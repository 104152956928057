.assignDriver {
    .MuiDialog-paper {
        min-height: 250px;
    }
    .MuiGrid-container {
        margin-top: auto;
        margin-bottom: auto;
    }
    .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
        padding: 0;
    }
    .MuiAutocomplete-root .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 8px 8px 3px;
    }
    .multiInput .MuiAutocomplete-tag {
        margin-top: 2px;
        margin-bottom: 2px;
        height: 24px;
    }
}