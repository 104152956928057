.sub-title {
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 0;
    &.first {
        margin-top: 0;
    }
}
.order-content {
    &__btn-group {
        padding-top: 5px;
        .city-input {
            border-radius: 4px 0 0 4px;
        }
    }
}
.additional-services-modal {
    .MuiDialogContent-root {
        padding: 0;
    }
}
.additionalServices {
    max-width: 96%;
    margin: auto;
    min-height: 34px;
    padding-top: 2px;
    .MuiCheckbox-root {
        padding: 5px 9px;
    }
    .slick-track {
        line-height: 1;
        box-sizing: border-box;
    }
    .formControlLabel {
        display: flex !important;
        align-items: center;
        .MuiTypography-root {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 125px;
        }
    }
    .slick-arrow {
        z-index: 9;
        margin-top: 2px;
        width: 22px;
        height: 22px;
        &::before {
            color: #222;
            font-size: 22px;
        }
    }
}