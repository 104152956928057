.tariff {
    margin-left: -10px;
    margin-right: -10px;
    margin-top: 10px;
    .slick-track {
        margin-left: 0;
        margin-right: 0;
    }
    &__item {
        background-color: #fff;
        box-shadow: 0 2px 10px rgb(0 0 0 / 15%);
        font-size: 14px;
        padding: 8px;
        border-radius: 8px;
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        cursor: pointer;
        &.active {
            background-color: #f5f4f2;
        }
    }
    &__img {
        max-width: 60px;
        width: 100%;
        display: block;
        margin: auto;
        margin-bottom: 3px;
    }
    &__title {
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 2px;
        min-height: 29px;

    }
    &__price {
        font-weight: 700;
        line-height: 1;
    }
}