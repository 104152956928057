/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
a {
    color: #fff;
    text-decoration: none;
}
$accent: #3f51b5;
.sidebar {
    background-color: #303841;
    color: #fff;
    width: 100%;
    transition: max-width 0.1s ease;
    z-index: 3;
    overflow-x: hidden;
    position: relative;
    &_large {
        max-width: 240px;
        .sidebar__logo-desctop {
            display: block;
        }
        .sidebar__logo-mobile {
            display: none;
        }
    }
    &_small {
        max-width: 72px;
        .sidebar__logo-desctop {
            display: none;
        }
        .sidebar__logo-mobile {
            display: block;
        }
        .login-menu {
            &::after {
                display: none;
            }
        }
        .profile {
            padding: 10px;
            justify-content: center;
            &__wrap {
                margin-right: 0;
                margin-bottom: 10px;
                width: 60px;
                height: 60px;
                font-size: 26px;
            }
            &__login {
                display: none;
            }
            &__logout {
                span {
                    margin: 0;
                    font-size: 35px;
                }
                .text {
                    display: none;
                }
            }
        }
        .menu-nav {
            &__item {
                .text {
                    opacity: 0;
                    transform: translate3d(-25px, 0px, 0px);
                    transition: transform 300ms ease 0s, opacity 300ms ease 0s;
                }
            }
        }
        .call-phone {
            left: 10px;
            bottom: 10px;
        }
        .profile-nav {
            &__item {
                a, 
                .logout__item, 
                .login-menu {
                    .text {
                        opacity: 0;
                        transform: translate3d(-25px, 0px, 0px);
                        transition: transform 300ms ease 0s, opacity 300ms ease 0s;
                    }
                }
            }
            &__drop {
                li .logout__item {
                    padding-left: 16px;
                }
            }
        }
        .sidebar-lang #lang_nav_menu {
            padding: 5px;
        }
    }
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 12px 8px;
        cursor: pointer;
        border-bottom: 1px solid #7070708a;
    }
    &__logo {
        font-size: 20px;
        font-weight: 700;
    }
    &__logo-mobile {
        display: none;
    }
    &__burger {
        display: flex;
        align-items: center;
        .material-icons {
            font-size: 30px;
        }
    }
}
.menu-nav {
    padding: 0;
    margin: 0;
    &__item {
        list-style: none;
        a {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            margin: 12px 8px;
            font-size: 14px;
            color: #fff;
            border-radius: 4px;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            .text {
                position: relative;
                display: block;
                white-space: nowrap;
                opacity: 1;
                transform: translate3d(0px, 0px, 0px);
                transition: transform 300ms ease 0s, opacity 300ms ease 0s;
                margin-left: 16px;
            }
            &:hover {
                text-decoration: none;
                background-color: rgba(63, 81, 181, 0.6);
            }
            &.is-active {
                text-decoration: none;
                background-color: $accent;
            }
            .material-icons {
                position: absolute;
                width: auto;
            }
        }
    }
}
.profile-nav {
    padding: 0;
    margin: 0;
    &__item {
        list-style: none;
        a,
        .logout__item,
        .login-menu {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            margin: 12px 8px;
            font-size: 14px;
            color: #fff;
            border-radius: 4px;
            transition: background-color 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
            cursor: pointer;
            .text {
                position: relative;
                display: block;
                white-space: nowrap;
                opacity: 1;
                transform: translate3d(0px, 0px, 0px);
                transition: transform 300ms ease 0s, opacity 300ms ease 0s;
                margin-left: 16px;
            }
            .material-icons {
                width: 24px;
                height: 24px;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                color: #303841;
                font-size: 18px;
                position: absolute;
            }
            &:hover {
                text-decoration: none;
                background-color: rgba(63, 81, 181, 0.6);
            }
        }  
    }
    &__drop {
        margin: 0;
        padding: 0;
        opacity: 0;
        visibility: hidden;
        transition: all .3s ease;
        &.active {
            opacity: 1;
            visibility: visible;
        }
        li {
            list-style: none;
            a {
                padding-left: 60px;
                .material-icons {
                    background-color: transparent;
                    color: #fff;
                    font-size: 1.5rem;
                }
            }
        }
    }
}
.profile {
    display: flex;
    align-items: center;
    padding: 15px;
    padding-bottom: 30px;
    flex-wrap: wrap;
    &__wrap {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background-color: $accent;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 30px;
        margin-right: 15px;
    }
    &__login {
        display: flex;
        align-items: center;
        font-size: 14px;
        margin-bottom: 5px;
        span {
            margin-right: 7px;
            font-size: 20px;
        }
    }
    &__logout {
        display: flex;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        transition: none;
        span {
            margin-right: 10px;
            font-size: 17px;
        }
        .text {
            font-size: 14px;
        }
    }
}
.sidebar-lang {
    margin: 12px 8px;
    #lang_nav_menu {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        svg {
            margin-right: 5px;
        }
        &::after {
            display: none;
        }
        &:focus {
            box-shadow: none;
            outline: none;
            border-color: $accent;
        }
    }
    .dropdown-item {
        display: flex;
        align-items: center;
        span {
            margin-right: 5px;
            margin-top: -5px;
        }
    }
}
.lang-icon {
    display: flex;
    align-items: center;
    .flag-icon {
        margin-top: -3px;
        margin-right: 3px;
    }
}
.sidebar-version {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #e0e0e0;
    font-size: 12px;
}

.profile-nav__drop li a,
.profile-nav__drop li .logout__item {
    padding-left: 52px;
}

.sidebar-version {
    position: absolute;
    right: 10px;
    bottom: 10px;
    color: #e0e0e0;
    font-size: 12px;
}