.loading {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 100%;
    text-align: center;
    margin: auto;
    &_fixed {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
    &_vertical-center {
        .MuiCircularProgress-root {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }
}
.map-container {
    position: relative;
    width: 100%;
    height: 100vh;
}