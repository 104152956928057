.authorization {
    padding: 30px;
    .MuiPaper-rounded {
        border-radius: 12px;
    }
    .btnWrap {
        display: flex;
        justify-content: flex-end;
    }
}
.numberWrap {
    display: flex;
    align-items: center;
    .MuiOutlinedInput-notchedOutline {
        border-radius: 5px 0 0 5px;
    }
    .MuiButtonBase-root {
        min-width: 180px;
        height: 40px;
        border-radius: 0 5px 5px 0;
    }
}
.title {
    text-align: center;
}