.draggableCard {
    &.addTariff {
        top: auto;
        left: auto;
        bottom: 10rem;
        right: 10rem;
        max-width: 660px;
        .MuiCardContent-root {
            padding-top: 6px;
        }
    }
}
.tabpanel {
    padding-top: 20px;
    padding-bottom: 10px;
    &-advanced {
        padding: 18px 8px 8px;
        border: 1px solid rgba(0, 0, 0, 0.23);
        border-radius: 0 4px 4px;
    }
}
.simplified {
    padding: 8px;
    padding-top: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    margin-bottom: 14px;
    position: relative;
    &-content {
        &.scroll {
            overflow-x: hidden;
            overflow-y: scroll;
            height: 155px;
            padding-top: 8px;
            padding-bottom: 8px;
            padding-right: 12px;
            margin-right: -14px;
            margin-bottom: 10px;
        }
    }
    &__item {
        padding-bottom: 8px;
        margin-bottom: 10px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.23);
        position: relative;
        &:last-of-type {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
    &-title {
        font-weight: 600;
        margin-top: 10px;
        margin-bottom: 10px;
    }
    &-sub-title {
        font-size: 12.5px;
        line-height: 1;
        position: absolute;
        top: -6px;
        left: 8px;
        background-color: #fff;
        padding-left: 3px;
        padding-right: 3px;
    }
    .delete {
        position: absolute;
        right: -14px;
        top: -14px;
        z-index: 3;
    }
    .btn-group {
        margin-bottom: 8px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
    &__btn {
        background-color: #fff;
        position: absolute;
        top: -16px;
        right: 8px;
        z-index: 2;
    }
}
.advanced-tariff {
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 4px;
    padding: 18px 10px 10px;
    position: relative;
    margin-bottom: 16px;
    &__btn {
        background-color: #fff;
        position: absolute;
        top: -16px;
        right: 8px;
        z-index: 2;
    }
    .MuiOutlinedInput-root {
        border-radius: 0;
    }
}
.btn-group {
    div {
        border-radius: 0;
    }
    .time {
        &.MuiTextField-root {
            max-width: 60px;
            width: 100%;
            input {
                padding-right: 6px;
                padding-left: 6px;
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border-left-color: transparent;
        }
    }
}