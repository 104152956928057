@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
@import "../../utils/vars.scss";

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
    line-height: 1.65;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
}
.wrapper {
    display: flex;
    width: 100%;
    align-items: stretch;
    height: 100vh;
    box-sizing: border-box;
}
.title {
    font-size: 24px;
    font-weight: 700;
}
.call-block {
    max-width: 340px;
    padding: 15px;
    .input-group {
        margin: 12px 0;
    }
}
.input-group {
    display: flex;
    align-items: center;
    .MuiOutlinedInput-notchedOutline {
        border-radius: 5px 0 0 5px;
    }
    .MuiButtonBase-root {
        padding-left: 12px;
        padding-right: 12px;
        height: 40px;
        border-radius: 0 5px 5px 0;
        min-width: 110px;
        margin-left: -1px;
    }
}

.call-phone {
    position: absolute;
    display: block;
    margin: 0;
    width: 50px;
    height: 50px;
    font-size: 24px;
    background-color: $accent;
    border-radius: 50%;
    box-shadow:
      0 0 0 0em rgba($accent, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
    transform: translate3d(0, 0, 0) scale(1);
    cursor: pointer;
    bottom: 12px;
    left: 12px;
    z-index: 999;
    color: #fff;
    border: none;
    outline: none;
    &.call-close::after {
        content: 'close';
    }
    &__number {
        span {
            opacity: .7;
        }
    }
}

.call-phone::before,
.call-phone::after {
    position: absolute;
    content: "";
}

.call-phone::before {
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    background-color: rgba(#fff, 0.1);
    border-radius: 100%;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
}

.call-phone::after {
    font-family: "Material Icons";
    content: "phone";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    display: inline-block;
    width: 25px;
    height: 25px;
    line-height: 1;
}

.call-phone.is-animating {
    animation: phone-outer 3000ms infinite;

    &::before {
        animation: phone-inner 3000ms infinite;
    }

    &::after {
        animation: phone-icon 3000ms infinite;
    }
}

@keyframes phone-outer {
    0% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    33.3333% {
        transform: translate3d(0, 0, 0) scale(1.1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0.1),
        0em 0.05em 0.1em rgba(#000000, 0.5);
    }
    66.6666% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0.5em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    100% {
        transform: translate3d(0, 0, 0) scale(1);
        box-shadow:
        0 0 0 0em rgba(#3498db, 0),
        0em 0.05em 0.1em rgba(#000000, 0.2);
    }
    }

    @keyframes phone-inner {
    0% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(0);
    }
    33.3333% {
        opacity: 1;
        transform: translate3d(0, 0, 0) scale(0.9);
    }
    66.6666% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0);
    }
    100% {
        opacity: 0;
        transform: translate3d(0, 0, 0) scale(0);
    }
}

@keyframes phone-icon {
    0% { transform: translate3d(0em, 0, 0); }
    2% { transform: translate3d(0.01em, 0, 0); }
    4% { transform: translate3d(-0.01em, 0, 0); }
    6% { transform: translate3d(0.01em, 0, 0); }
    8% { transform: translate3d(-0.01em, 0, 0); }
    10% { transform: translate3d(0.01em, 0, 0); }
    12% { transform: translate3d(-0.01em, 0, 0); }
    14% { transform: translate3d(0.01em, 0, 0); }
    16% { transform: translate3d(-0.01em, 0, 0); }
    18% { transform: translate3d(0.01em, 0, 0); }
    20% { transform: translate3d(-0.01em, 0, 0); }
    22% { transform: translate3d(0.01em, 0, 0); }
    24% { transform: translate3d(-0.01em, 0, 0); }
    26% { transform: translate3d(0.01em, 0, 0); }
    28% { transform: translate3d(-0.01em, 0, 0); }
    30% { transform: translate3d(0.01em, 0, 0); }
    32% { transform: translate3d(-0.01em, 0, 0); }
    34% { transform: translate3d(0.01em, 0, 0); }
    36% { transform: translate3d(-0.01em, 0, 0); }
    38% { transform: translate3d(0.01em, 0, 0); }
    40% { transform: translate3d(-0.01em, 0, 0); }
    42% { transform: translate3d(0.01em, 0, 0); }
    44% { transform: translate3d(-0.01em, 0, 0); }
    46% { transform: translate3d(0em, 0, 0); }
}

.call-popup {
    color: #333;
    transition: all .3s ease;
    &__content {
        position: fixed;
        left: 75px;
        bottom: 75px;
        background: #fff;
        box-shadow: 0 3px 14px rgba(17,34,51,0.16);
        padding: 15px;
        z-index: 999;
        max-width: 320px;
    }
    &__backdrop {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(0, 0, 0, .3);
        z-index: 99;
    }
    &__close {
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
    }
    &.hide {
        visibility: hidden;
        opacity: 0;
    }
    &.show {
        opacity: 1;
        visibility: visible;
    }
    .input-group {
        padding: 10px 0 15px;
        input, button {
            border-radius: 0;
        }
        input:focus {
            box-shadow: none;
            outline: none;
            border-color: #28a745;
        }
    }
}

.form-group {
    .input-group-prepend {
        z-index: 2;
    }
    .input-group {
        select {
            margin-left: -3px;
        }
    }
}
.date-group {
    input {
        line-height: 32px;
        max-width: 332px;
        width: 100%;
        padding-left: 10px;
        &:last-child {
            margin-left: -1px;
        }
    }
    .react-datepicker__input-container {
        min-width: 332px;
        width: 100%;
    }
    .react-datepicker-wrapper {
        max-width: 330px;
        width: 100%;
    }
}
.customer-search {
    .btn {
        width: 100%;
    }
}
.call-phone-scroll {
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
}

/* TOASTIFY */

.Toastify__toast-container{
    padding: 0;
}

.Toastify__toast {
    padding: 0;
    margin-bottom: 0;
}

.Toastify__toast-container--bottom-right {
    bottom: 0.5em;
    right: 0.5em;
}

/*-------------------------------------------*\
    Notification style
\*-------------------------------------------*/

div.notificationRow {
    min-height: 72px;
    min-width: 317px;
    max-width: 396px;
    background: #495057;
    border: none;
    padding: 12px;
    padding-right: 10px;
    color: #fff;
    font-size: 14px;
    box-sizing: border-box;
    border-radius: 0;
}
.notification {
    margin-bottom: 16px;
    &:last-child {
        margin-bottom: 0;
        .notification__desc {
            margin-bottom: 0;
        }
    }
    &__title {
        font-weight: 500;
        margin-bottom: 8px;
        text-align: center;
    }
    &__content {
        background: #343a40;
        padding: 12px;
        box-sizing: border-box;
        font-weight: 300;
        text-align: center;
        position: relative;
    }
    svg.notification__more {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 26px;
        cursor: pointer;
        transition: all 0.3s ease;
        &.toggle {
            transform: rotate(180deg);
        }
    }
    &__desc {
        font-weight: 300;
        margin-bottom: 8px;
        text-align: left;
        white-space: pre-wrap;
        margin-right: 18px;
    }
    button {
        color: #fff;
        background-color: #6c757d;
        box-shadow: none;
        border-radius: 0;
        text-transform: none;
        font-weight: 400;
        padding-left: 16px;
        padding-right: 16px;
        margin-right: 5px;
        &:last-child {
            margin-right: 0;
        }
        &:hover {
            box-shadow: none;
        }
        &.active {
            background-color: $accent;
            color: #ffffff;
        }
    }
    &__infoClient {
        text-align: left;
        color: #fff;
        font-size: 12px;
        font-weight: 300;
        line-height: 1.4;
    }
    &__textField {
        padding-bottom: 14px;
        padding-top: 4px;
        .MuiInputBase-root,
        .MuiFormLabel-root {
            color: #fff;
            font-size: 14px;
            &:hover {
                .MuiOutlinedInput-notchedOutline {
                    border-color: #fff;
                } 
            }
            &.Mui-focused {
                color: #fff;
                .MuiOutlinedInput-notchedOutline {
                    border-color: #fff;
                }
            }
        }
        .MuiOutlinedInput-notchedOutline {
            border-color: #fff;
        }
        // .MuiInputLabel-outlined {
        //     &.MuiInputLabel-shrink {
        //         transform: translate(14px, -6px) scale(0.75);
        //     }
        // }
    }
    .agencyButton {
        display: none;
    }
    &.fixedButton {
        .notification__content {
            background-color: transparent;
            padding: 0;
        }
        .buttonGroup {
            button {
                height: 50px;
                max-width: 74px;
                width: 100%;
                box-sizing: border-box;
                position: relative;
                padding: 0;
                font-size: 12px;
                .MuiSvgIcon-root {
                    position: absolute;
                    top: 5px;
                    left: 5px;
                    font-size: 20px;
                }
                .buttonText {
                    position: absolute;
                    bottom: 7px;
                    left: 7px;
                    line-height: 1;
                }
            }
            .agencyButton {
                display: inline-flex;
            }
        }
    }
}

.notificationGroupRow {
    margin-bottom: 16px;
}
.notificationContent {
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
}
.notificationGroup {
    background-color: #343a40;
    padding: 12px;
    box-sizing: border-box;
    font-weight: 300;
    transition: all .5s ease;
    overflow: hidden;
    &__iconGroup {
        position: absolute;
        right: 0;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        max-width: 24px;
        .MuiSvgIcon-root {
            font-size: 20px;
            cursor: pointer;
        }
    }
    &__clear {
        margin-left: 10px;
        display: inline-block;
    }
    .notification__more {
        font-size: 24px;
        &.toggle {
            transform: rotate(180deg);
        }
    }
    &_all {
        overflow-y: scroll;
        .notificationGroup__item {
            &:last-child {
                padding-bottom: 12px;
            }
        }
    }
    &__item {
        border-bottom: 1px solid #777777;
        padding-bottom: 12px;
        margin-bottom: 12px;
        padding-right: 24px;
        position: relative;
        &:last-child {
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
        }
        // &:nth-child(3) {
        //     margin-top: 12px;
        //     padding-top: 12px;
        //     border-top: 1px solid #777777;
        // }
    }
    &__title {
        font-weight: 500;
    }
    // &__desc {
    //     word-wrap: break-word;
    // }
    &__date {
        font-size: 14px;
        margin-top: 6px;
        opacity: .7;
    }
    &__more {
        text-align: center;
        font-size: 14px;
        color: #36a5ff;
        margin-top: 8px;
        span {
            cursor: pointer;
            transition: all .3s ease;
            &:hover {
                color: #fff;
            }
        }
    }
}

.notification-call {
    .notification__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .btn-success {
        background-color: #28a745;
    }
    .btn-danger {
        background-color: #dc3545;
    }
}

.btn-group {
    .btn-success {
        background-color: green;
    }
    .btn-secondary {
        background-color: red;
    }
    .MuiButton-startIcon {
        margin-right: 0;
    }
}

.getAddressAutocomplete {
    width: 100%;
    height: 40px;
    padding: 0 8px;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 4px;
    border: 1px solid #dddddd;
    font-size: 1rem;
    box-sizing: border-box;
    font-family: "Roboto", sans-serif;
    &::placeholder {
        color: rgba(0, 0, 0, 0.54);
    }
    &:focus {
        outline-color: #3f51b5;
    }
    &-city {
        color: #777777;
        font-size: 13px;
    }
    &-row {
        position: relative;
        .done-icon {
            position: absolute;
            right: 30px;
            top: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            opacity: .7;
            font-size: 20px;
        }
    }
    &-icon {
        &.MuiSvgIcon-root {
            position: absolute;
            right: 8px;
            top: 0;
            bottom: 0;
            margin: auto;
            cursor: pointer;
            opacity: .7;
            font-size: 20px;
        }
    }
    &-error {
        position: absolute;
        margin-top: 1px;
        border: 1px solid #dddddd;
        padding: 5px;
        font-size: 12px;
        color: #cc000c;
        z-index: 9;
        background-color: #fff;
        border-radius: 5px;
    }
}

.advancedOption {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    margin-top: -3px;
    font-size: 1rem;
    z-index: 999;
    &__list {
        list-style: none;
        padding: 0;
        padding-top: 10px;
        padding-bottom: 10px;
        margin: 0;
        max-height: 350px;
        overflow-y: scroll;
    }
    &__item {
        padding: 4px 10px;
        transition: all .3s ease;
        &:hover {
            cursor: pointer;
            background-color: rgb(233, 233, 233);
        }

    }
}

.rollUp {
    opacity: 0;
    visibility: hidden;
    &None {
        opacity: 1;
        visibility: visible;
    }
} 
.rollUpBtnRow {
    position: absolute;
    left: 0;
    bottom: 5px;
    z-index: 9;
    button.rollUpBtn {
        padding-right: 8px;
        padding-left: 3px;
        margin-left: 3px;
        text-transform: none;
    }
    .MuiBadge-colorSecondary {
        background-color: #f50057;
    }
    .MuiBadge-colorPrimary {
        background-color: #28a745;
    }
}

.sidebar_small {
    .sidebar-version {
        bottom: 70px;
    }
}
.draggableCard {
    position: absolute;
    top: 3rem;
    left: 8px;
    z-index: 999;
    max-width: 1200px;
    width: 100%;
    &__title {
        font-size: 20px;
        font-weight: 500;
    }
    &__wrap {
        position: absolute;
        top: 10px;
        right: 5px;
    }
    .MuiCardHeader-content {
        margin-right: 100px;
    }
    .MuiCardHeader-root {
        padding-bottom: 0;
    }
}
.cancelDraggable {
    cursor: auto;
}

.react-resizable-handle {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 40px;
    height: 40px;
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=);
    background-position: bottom right;
    padding: 18px;
    background-repeat: no-repeat;
    background-origin: content-box;
    box-sizing: border-box;
    cursor: se-resize;
    z-index: 9999;
}

.infoWindow {
    p {
        margin: 0;
        margin-bottom: 3px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    &__content {
        margin-bottom: 6px;
    }
    &__link {
        color: blue;
        cursor: pointer;
        line-height: 1.4;
        &:hover {
            text-decoration: none;
        }
    }
}
.reset-button {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 999;
}