.draggableCard {
    &.orderRegistration {
        top: auto;
        left: auto;
        bottom: 10rem;
        right: 10rem;
        max-width: 730px;
        .MuiCardContent-root {
            padding-top: 6px;
        }
    }
}
