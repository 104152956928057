button.lang-button {
    color: #fff;
    border: 1px solid #fff;
    box-sizing: border-box;
    margin-left: 1px;
    min-width: 54px;
    padding: 5.5px 3px;
}
.login-menu {
    position: relative;
    &::after {
        content: '';
        position: absolute;
        border: 6px solid transparent; 
        border-top: 6px solid #fff;
        right: 10px;
        top: 6px;
        bottom: 0;
        margin: auto;
        width: 6px;
        height: 6px;
        box-sizing: border-box;
    }
}