.orderInfoModal {
    .MuiInputBase-root,
    .MuiFormLabel-root {
        font-size: 14px;
        &.MuiInputLabel-shrink {
            transform: translate(0, 1.5px) scale(0.90);
        }
    }
    .MuiDialog-paper {
        min-height: 500px;
    }
    .MuiGrid-container {
        margin-top: auto;
        margin-bottom: auto;
    }
}