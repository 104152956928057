@import "../../utils/vars.scss";
.draggableCard {
    &.tariffs {
        top: auto;
        left: auto;
        bottom: 10rem;
        right: 10rem;
        max-width: 760px;
    }
}
.tariff-table {
    max-height: 700px;
    position: relative;
    .table-link {
        color: $accent;
        cursor: pointer;
    }
}
.tariff-filter {
    margin: 10px 0;
    padding: 0;
    position: relative;
    border-bottom: 1px solid rgba(0, 0, 0, 0.54);
    padding-top: 18px;
    padding-bottom: 5px;
    &__row {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        box-sizing: border-box;
        max-height: 80px;
        overflow-y: auto;
        padding-right: 35px;
        margin-top: 3px;
        min-height: 22px;
    }
    .tariff-filter__edit {
        position: absolute;
        right: 10px;
        top: 6px;
    }
    &__label {
        color: rgba(0, 0, 0, 0.54);
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        transform-origin: top left;
        transform: translate(0, 1.5px) scale(0.75);
        transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
        font-family: "Roboto";
    }
    li {
        list-style: none;
        padding-right: 4px;
    }
}
.tabpanel-advanced {
    max-height: 460px;
    overflow-y: auto;
}