.callEnd {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border: 1px solid #dc3545;
    border-radius: 0 .25rem .25rem 0;
    margin-left: -2px;
    padding: 3px 5px;
    transition: all .3s ease;
    background-color: #fff;
    &__icon {
        width: 28px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background-color: #dc3545;
    }
    .MuiSvgIcon-root {
        font-size: 20px;
    }
    &:hover {
        background-color: #dc3545;
        border-color: #dc3545;
        cursor: pointer;
    }
    &-green {
        border-color: #28a745;
        border-radius: 0;
        .callEnd__icon {
            background-color: #28a745;
        }
        &:hover {
            background-color: green;
            border-color: green;
            cursor: pointer;
        }
    }
}
.input-group-prepend {
    .input-group-text {
        padding: .375rem .75rem;
        background-color: #e9ecef;
        border: 1px solid #ced4da;
        border-radius: .25rem;
        &:first-child {
            border-radius: .25rem 0 0 .25rem;
        }
        &:last-child {
            border-radius: 0 .25rem .25rem 0;
            margin-left: -1px;
        }
    }
}
.input-group-append {
    display: flex;
}