.buttonGroup {
    button {
        height: 50px;
        max-width: 74px;
        width: 100%;
        box-sizing: border-box;
        position: relative;
        padding: 0;
        font-size: 12px;
        .MuiSvgIcon-root {
            position: absolute;
            top: 5px;
            left: 5px;
            font-size: 20px;
        }
        .buttonText {
            position: absolute;
            bottom: 7px;
            left: 7px;
            line-height: 1;
        }
    }
    .agencyButton {
        display: inline-flex;
    }
}