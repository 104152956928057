.activeOrdersTable {
    max-height: 600px;
    th, 
    td {
        box-sizing: border-box;
        white-space: nowrap; 
        text-overflow:ellipsis; 
        overflow: hidden; 
        max-width: 140px;
    }
    tr {
        transition: all .3s ease;
        &:hover {
            cursor: pointer;
            background-color: #0000000a;
        }
    }
}